@import '../../../styles/customMediaQueries.css';

.sectionWrapper {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: 0 12px;
  max-width: calc(1052px + 36px + 36px);
  margin: 0 auto;
  margin-top: 8rem;
  margin-bottom: 8rem;
  gap: 92px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    gap: 12px;
  }
}

.grid {
  flex: 2;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 34px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 12px;
  }
}

.column {
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 32px;
  max-width: 320px;

  @media (max-width: 768px) {
    /* padding: 24px; */
    max-width: 900px;

    /* margin-bottom: 20px; */
  }
}

.card {
  height: 100%;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--gray-300);
  border-radius: 10px;
  flex-direction: column;
  flex: 0.46;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    box-shadow: 2px 2px 15px 1px rgba(0, 0, 0, 0.3);
    transition: 0.3s;
  }
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 12px;
  padding: 16px;
  /* margin-bottom: 30px; */

  @media (max-width: 768px) {
    padding: 12px;

    /* margin-bottom: 20px; */
  }
}

.titleWrapper2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-bottom: 42px;
  
  @media (max-width: 768px) {
    padding: 24px;
    margin-bottom: 20px;

    /* margin-bottom: 20px; */
  }
}

.cardTitle {
  font-size: 20px;
  font-weight: 500;
  line-height: 35px;
}

.icons {
  fill: white;
  width: 200px;
}

.iconAngle {
  text-align: right;
  margin-right: -20px;
}
.card:hover > .iconAngle > svg > path {
  stroke: #1ead52;
}

.listItem {
  padding-left: 2.5rem;
  position: relative;
  line-height: 24px;
  margin-bottom: 1rem;
  font-size: 14px;
}

.checkBullets {
  fill: white;
  position: absolute;
  left: 0;
  top: 5px;
}

.cardRight {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 24px;
  /* color: white; */
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.5s ease;
}

.imageWrapper {
  height: 220px;
  overflow: hidden;
}
