@import '../../../styles/customMediaQueries.css';

.sectionContainer {
  padding: 8rem 12px;
  max-width: calc(1052px + 12px + 12px);
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 38px 12px;
  }
}

.grid {
  flex: 2;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 1rem;
}

.card {
  height: 100%;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--gray-300);
  border-radius: 10px;
  flex-direction: column;
  flex: 0.46;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    box-shadow: 2px 2px 15px 1px rgba(0, 0, 0, 0.3);
    transition: 0.3s;
  }
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 12px;
  padding: 16px;
  /* margin-bottom: 30px; */

  @media (max-width: 768px) {
    padding: 12px;

    /* margin-bottom: 20px; */
  }
}

.titleWrapper2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-bottom: 42px;
  
  @media (max-width: 768px) {
    padding: 24px;
    margin-bottom: 20px;

    /* margin-bottom: 20px; */
  }
}

.cardTitle {
  font-size: 20px;
  font-weight: 500;
  line-height: 35px;
}

.header {
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;

  @media (max-width: 768px) {
    text-align: center;
  }
}

.icons {
  fill: white;
  width: 100px;
  transition: 0.3s;
}

.image {
  width: 100%;
  height: 100%;
  max-height: 240px;
  object-fit: cover;
} 
.listItem {
  padding-left: 2.5rem;
  position: relative;
  line-height: 28px;
  margin-bottom: 1rem;
  font-size: 15px;
}

.checkBullets {
  fill: white;
  position: absolute;
  left: 0;
  top: 5px;
}

.cardRight {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  font-size: 15px;
  line-height: 28px;
}

.heroButton {
  min-height: 45px !important;
  height: 57px;
  font-size: 15px;
  width: fit-content;
  padding: 0px 70px;
  background-color: var(--brand-500);
  margin-top: 20px;

  @media (max-width: 768px) {
    width: 95%;
  }
}
