.background {
  background-color: rgba(229, 229, 229, 0.4);
}

.root {
  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1052px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px;
  }

  @media (max-width: 768px) {
    width: 95%;
    margin: auto;
  }

  margin-bottom: 3rem;
  margin-top: 3rem;
}

.header {
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;

  @media (max-width: 768px) {
    text-align: center;
  }
}

.logoWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 64px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: space-around;
  }
}

.logo {
  width: 20%;

  @media (max-width: 768px) {
    width: 40%;
  }
}
