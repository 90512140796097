@import '../../../styles/customMediaQueries.css';

.root {
  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1052px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px;
  }

  @media (max-width: 768px) {
    width: 95%;
    margin: auto;
  }

  margin-bottom: 4rem;
  margin-top: 3rem;
}

.header {
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;

  @media (max-width: 768px) {
    text-align: center;
  }
}

.sectionWrapper {
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
  }
}

.column {
  width: 50%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.card {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);

  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  flex: 0.43;
  justify-content: center;
  position: relative;

  @media (max-width: 768px) {
    margin: 2.5rem 0;
  }
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cardTitle {
  font-size: 22px;
  font-weight: 500;
  line-height: 35px;
}

.icons {
  fill: white;
}

.listItem {
  padding-left: 2.5rem;
  position: relative;
  line-height: 28px;
  margin-bottom: 1rem;
  font-size: 15px;
}

.checkBullets {
  fill: white;
  position: absolute;
  left: 0;
  top: 5px;
}

.cardRight {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 12px;
  line-height: 24px;
}

.author {
  margin: 0.5rem;
  color: rgba(0, 0, 0, 0.5);
}

.profilePicWrapper {
  position: absolute;
  left: -30px;
  top: calc(50% - 30px);
  border-radius: 50%;
  width: 60px;
  overflow: hidden;
  -webkit-filter: drop-shadow(0 4px 25px rgba(0, 0, 0, 0.15));
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.15));
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  @media (max-width: 768px) {
    top: -30px;
    left: calc(50% - 30px);
  }
}

.profilePic {
  width: 100%;
}

.heroButton {
  min-height: 45px !important;
  margin-top: 3rem;
  height: 57px;
  font-size: 15px;
  width: fit-content;
  padding: 0px 70px;
  float: right;
  background-color: var(--brand-500);

  @media (max-width: 768px) {
    margin-top: 0.5rem;
    width: 100%;
  }
}
