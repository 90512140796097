@import '../../../styles/customMediaQueries.css';

.section {
  display: flex;
  justify-content: center;
  background-color: #f3f3f3;
  padding: 2rem 0;
  line-height: normal;

  @media (--viewportMedium) {
    padding: 4rem 0;
  }
}

.container {
  display: flex;
  background-color: white;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  gap: 1rem;
  max-width: 1056px;
  padding: 24px;
  border-radius: 8px;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 12px;

  @media (--viewportMedium) {
    flex-direction: row;
    padding: 32px;
    gap: 3rem;
  }
}

.leftWrapper {
  flex: 1;
}

.rightWrapper {
  flex: 2;
}

.header {
  font-weight: 500;
  font-size: 16px;
  line-height: normal;
}

.header2 {
  font-weight: 500;
  font-size: 24px;
  line-height: normal;
  color: var(--secondary-500);
}

.inputGroup {
  display: flex;
  gap: 1rem;
}

.input {
  border: 2px solid #d0d5dd;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  width: 100%;
  outline: none;
}

.inputGroup {
  display: flex;
  gap: 1rem;
  margin-bottom: 12px;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.input {
  border: 2px solid #d0d5dd;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  width: 100%;
  flex: 4;
  height: 48px;
}

.checkboxGroup {
  display: flex;
  gap: 1rem;
  padding: 8px 0;
}

.checkbox {
  border: 2px solid #d0d5dd;
  border-radius: 8px;
  height: 18px;
  width: 18px;
}

.checkboxLabel {
  font-size: 12px;
  flex: 100;
}

.heroButton {
  background-color: var(--brand-500);
  color: white;
  cursor: pointer;
  height: 48px !important;
  min-height: auto;
  flex: 1;
  padding: 8px 24px;
}

.errorLabel {
  color: red;
  font-size: 14px;
  margin-bottom: 8px;
}

.modalTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 16px;
}